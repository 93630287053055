import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box, Button, Anchor, TextInput, Select } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';
import { TitleField } from '../../../../components/form';

import { formattedDateTime, formattedCurrency, formattedNumber } from '../../../../utils';

import selectCampaignTypeOptions from '../../../../constants/select-campaign-type-options';
import visibleWidth from '../../../../constants/layer-width';

import { changeValues } from '../../../../actions/form';
import { toggleBuyLayer, create, recalculate } from '../../actions/communication-balance';

class BuyCommunBalance extends Component {
  handlePost = formParams => {
    const { create } = this.props;

    create({
      formParams
    });
  };

  handleOpenBankSlip = url => {
    window.open(url, '_blank');
  };

  handleRecalculate = formParams => {
    const { recalculate } = this.props;
    recalculate(formParams);
  };

  render() {
    const {
      typeCommunicationId,
      amount,

      toggleLayer,
      loadingPostForm,
      dataSuccess,
      postDataError,

      costBankSlip,
      costUnit,
      costTotal,

      changeValues,
      toggleBuyLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'buyCommunicationBalance')
      return null;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={toggleBuyLayer}
            onEsc={toggleBuyLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ vertical: size === 'small' ? 'xlarge' : 'large' }}
              overflow='auto'
              flex
            >
              {/*!detail ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null*/}

              {dataSuccess ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value='Pacote de comunicação' />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={toggleBuyLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    <SimpleDetail
                      title='Vencimento'
                      value={formattedDateTime(dataSuccess.dueDate)}
                      description='O boleto ainda poderá ser pago após o vencimento.'
                      width={{ title: '30vw', value: '70vw' }}
                    />

                    <Hr />

                    <SimpleDetail
                      title='Quantidade'
                      value={formattedNumber(dataSuccess.amount)}
                      description='A quantidade será disponibilizada após a confirmação do pagamento.'
                      width={{ title: '30vw', value: '70vw' }}
                    />

                    <SimpleDetail
                      title='Total'
                      value={formattedCurrency(dataSuccess.totalValue)}
                      description='Valor total da compra.'
                      width={{ title: '30vw', value: '70vw' }}
                    />

                    <Hr />

                    <SimpleDetail
                      title='Link'
                      value={dataSuccess.url}
                      truncateValue
                      description='O boleto também pode ser acessar pela Área do cliente no link "Faturas".'
                      width={{ title: '50vw', value: '50vw' }}
                    />
                  </Box>


                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Fechar'
                        onClick={toggleBuyLayer}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label='Abrir boleto'
                        onClick={() =>
                          this.handleOpenBankSlip(dataSuccess.url)
                        }
                      />
                    </Box>
                  </Box>
                </>
              ) : null}

              {!dataSuccess ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value='Comprar pacote de comunicação' />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={toggleBuyLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    <SimpleDetail
                      title='Valor unitário'
                      value={formattedCurrency(costUnit, 3)}
                    />

                    <SimpleDetail
                      title='Valor emissão PIX'
                      value={formattedCurrency(costBankSlip)}
                    />

                    <Hr />

                    <SimpleDetail
                      title='Total'
                      value={formattedCurrency(costTotal)}
                      description='Valor total que será cobrado.'
                      width={{ title: '70vw', value: '30vw' }}
                    />

                    <SimpleDetail
                      title='Sobre a compra'
                      description='O pacote comprado têm validade de 6 (seis) meses e poderá ser utilizado em todas as funcionalidades do sistema.'
                      width={{ title: '50vw', value: '50vw' }}
                    />

                    <Hr />

                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Box direction='row' gap='small'>
                        <Box width='40vw'>
                          <TitleField text='Pacote' required />

                          <Select
                            options={selectCampaignTypeOptions}
                            value={typeCommunicationId ?? ''}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            onChange={({ value: nextValue }) => changeValues({ fieldName: 'typeCommunicationId', value: nextValue }, 'formChangeValues')}
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='typeCommunicationId' />
                        </Box>

                        <Box width='60vw'>
                          <TitleField text='Quantidade' required />

                          <Box
                            pad={{ horizontal: 'xsmall' }}
                            round={{'size': '4px'}}
                            width='medium'
                            direction='row'
                            align='center'
                            border
                            fill='horizontal'
                          >
                            <TextInput
                              plain
                              value={amount ?? ''}
                              type='number'
                              min={300}
                              onChange={event =>
                                changeValues({ fieldName: 'amount', value: event.target.value }, 'formChangeValues')
                              }
                            />

                            <Button
                              gap='small'
                              label='calcular'
                              primary
                              color='light-4'
                              size='small'
                              onClick={() =>
                                this.handleRecalculate({
                                  typeCommunicationId,
                                  amount,
                                })
                              }
                            />
                          </Box>

                          <ErrorForm errorsList={postDataError.errors} fieldName='amount' />
                        </Box>
                      </Box>
                    </Box>
                  </Box>


                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        onClick={toggleBuyLayer}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                        disabled={ loadingPostForm }
                        onClick={() =>
                          this.handlePost({
                            typeCommunicationId,
                            amount,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({
  layerReducer,
  formCreateUpdateCopyGeneric,
  customerAreaReducer: { recalculate }
}) => ({
  typeCommunicationId: formCreateUpdateCopyGeneric.typeCommunicationId,
  amount: formCreateUpdateCopyGeneric.amount,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  dataSuccess: formCreateUpdateCopyGeneric.dataSuccess,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  costBankSlip: recalculate.costBankSlip ?? 0,
  costUnit: recalculate.costUnit ?? 0,
  costTotal: recalculate.costTotal ?? 0,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeValues,
    toggleBuyLayer,
    create,
    recalculate,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BuyCommunBalance);
