import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, Box, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { TitleDetail } from '../../../../components/detail';

import { remove, toggleFormDeleteLayer } from '../../actions/form-delete';

import visibleWidth from '../../../../constants/layer-width';

class FormDelete extends Component {
  handlePost = formParams => {
    const { remove } = this.props;
    remove({ formParams });
  };

  render() {
    const {
      id,
      name,

      toggleLayer,
      loadingPostForm,
      postDataError,
      toggleFormDeleteLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'delete') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormDeleteLayer}
              onEsc={toggleFormDeleteLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Remover origem' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleFormDeleteLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  <Box
                    flex={{ shrink: 0 }}
                    margin={{ horizontal: 'large' }}
                    gap='xsmall'
                  >
                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Nome</Text>
                      <Text size='medium' color='dark-4'>{name}</Text>
                    </Box>

                    <Box
                      margin={{ top: 'medium' }}
                    >
                      <Text
                        size='small'
                        textAlign='start'
                        color='status-warning'
                      >
                        Após a confirmação a origem será PERMANENTEMENTE excluida
                      </Text>
                    </Box>

                    <Box
                      flex={{ shrink: 0 }}
                    >
                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>
                  </Box>
                </Box>

                {/* botoes acao */}
                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormDeleteLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='status-error'
                      primary
                      label={ !loadingPostForm ? 'SIM, Remover': 'Removendo...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ settingsOriginReducer: { remove }, layerReducer }) => ({
  id: remove.id,
  name: remove.name,

  loadingPostForm: remove.loadingPostForm,
  postDataError: remove.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    remove,
    toggleFormDeleteLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormDelete);
