import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Text, Box, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, TagStatus, Description, TitleDetail } from '../../../../components/detail';

import { formattedDateTime } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';

class DetailsMessage extends Component {

  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'details') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleDetailsLayer}
              onEsc={toggleDetailsLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{
                  top: size === 'small' ? 'xlarge' : 'large',
                  bottom: size === 'small' ? 'large' : null,
                }}
                overflow='auto'
                flex
              >
                {getDetailError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: toggleDetailsLayer,
                    }}
                  />
                ) : null}

                {!detail && !getDetailError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {detail ? (
                  <>
                    <Box
                      direction='row'
                      justify='between'
                      align='baseline'
                      margin={{ horizontal: 'large' }}
                    >
                      <TitleDetail value={detail.subject} />

                      <Button
                        plain
                        title='Fechar'
                        icon={<FormClose />}
                        onClick={toggleDetailsLayer}
                      />
                    </Box>


                    <Box flex overflow='auto'>
                      <SimpleDetail
                        title='Dia'
                        value={formattedDateTime(detail.startDate, 'DD MMM YYYY')}
                      />

                      <SimpleDetail
                        title='Início'
                        value={formattedDateTime(detail.startDate, 'HH:mm')}
                      />

                      <SimpleDetail
                        title='Previsão de término'
                        value={formattedDateTime(detail.endDate, 'HH:mm')}
                      />

                      {detail.location.local &&
                        <SimpleDetail
                          title='Local'
                          value={detail.location.local}
                        />
                      }

                      <TagStatus
                        title='Status'
                        value={detail.isCanceled ? 'CANCELADO' : 'ATIVO'}
                        color={detail.isCanceled ? 'status-critical' : 'status-ok'}
                      />

                      <Hr />

                      {detail.description &&
                        <>
                          <Description
                            title='Descrição'
                            value={detail.description}
                          />

                          <Hr />
                        </>
                      }

                      {detail.participants && detail.participants.length > 0 &&
                        <Box
                          margin={{
                            top: 'xsmall',
                            bottom: 'none',
                            horizontal: 'large'
                          }}
                          pad={{ bottom: 'small' }}
                          flex={{ shrink: 0 }}
                        >
                          <Text size='medium' color='dark-2' margin={{ bottom: 'medium' }}>
                            Participantes
                          </Text>

                          <Box
                            direction='row'
                            flex={{ shrink: 0 }}
                            wrap={true}
                            gap='small'
                          >
                            {
                              detail.participants.map((participant, index) => (
                                <Box
                                  key={index}
                                  border={{ color: 'light-4' }}
                                  round='xsmall'
                                  background='light-1'
                                  pad={{ vertical: 'small', horizontal: 'medium' }}
                                  flex={{ shrink: 0 }}
                                  margin={{ bottom: 'small' }}
                                >
                                  <Text size='medium' color='dark-2'>
                                    {participant.name}
                                  </Text>
                                </Box>
                              ))
                            }
                          </Box>
                        </Box>
                      }
                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsMessage);
