import React from 'react';
import { useHistory } from 'react-router-dom';
import { ResponsiveContext, Box, Text, Anchor, DropButton } from 'grommet';

import optionsMenu from './options-menu';
import urlRoutes from '../../config/url-routes';
import rulesName from '../../constants/rules';

function DropModule(props) {
  const history = useHistory();

  const {
    userLogged: { user, rules }
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          gap='medium'
          align='center'
        >
          <Box
            direction='row'
            gap='medium'
            align='center'
          >
            {optionsMenu
              .filter(a => a.location === 'primary')
              .map(item => {
                if(item.present.includes(size)) {
                  return (
                    <Anchor
                      key={item.label}
                      label={item.label}
                      color='white'
                      size='small'
                      onClick={() => history.push(item.click)}
                    />
                  )
                }

                return null;
            })}
          </Box>

          <Box>
            <DropButton
              // stype Button
              size='small'
              label='Mais'
              color='white'
              primary

              // stype drop
              dropAlign={{ top: 'top' }}
              dropContent={
                <Box
                  gap='large'
                  direction='row'
                  background='light-1'
                  pad={{ vertical: 'large', horizontal: 'large' }}
                  flex={{ shrink: 0 }}
                >
                  {/* secondMenu */}
                  <Box
                    gap={ size === 'small' ? 'large' : 'medium' }
                  >
                    <Text size='small'>Outros módulos</Text>

                    <Box gap={ size === 'small' ? 'medium' : 'small' }>
                      {optionsMenu
                        .filter(a => a.location === 'primary')
                        .map(item => {
                          if(!item.present.includes(size)) {
                            return (
                              <Anchor
                                key={item.label}
                                label={item.label}
                                color='brand'
                                size='small'
                                onClick={() => history.push(item.click)}
                              />
                            )
                          }

                          return null;
                      })}

                      {optionsMenu
                        .filter(a => a.location === 'second')
                        .map(item => (
                          <Anchor
                            key={item.label}
                            label={item.label}
                            color='brand'
                            size='small'
                            onClick={() => history.push(item.click)}
                          />
                      ))}

                      <Anchor
                        href={urlRoutes.voterRegistrationForm}
                        label='Ficha captação eleitor'
                        color='brand'
                        size='small'
                        target='_blank'
                      />

                      {(user.profileId === 1 || rules.includes(rulesName.accessAnalytic)) && (<Box
                        direction='row'
                        gap='xsmall'
                      >
                        <Anchor
                          href={urlRoutes.analyticsUrl}
                          label='Analytics'
                          color='brand'
                          size='small'
                          target='_blank'
                        />

                        <Text color='status-ok' size='xsmall'>Novo</Text>
                      </Box>)}

                      {(user.profileId === 1 || rules.includes(rulesName.accessElection)) && (<Box
                        direction='row'
                        gap='xsmall'
                      >
                        <Anchor
                          href={urlRoutes.electionsUrl}
                          label='Eleições'
                          color='brand'
                          size='small'
                          target='_blank'
                        />

                        <Text color='status-ok' size='xsmall'>Beta</Text>
                      </Box>)}


                    </Box>
                  </Box>

                  {user.profileId === 1 && (<Box border={{ side: 'right', color: 'light-4' }} />)}

                  {/* adm */}
                  {user.profileId === 1 && (
                    <Box
                      gap={ size === 'small' ? 'large' : 'medium' }
                    >
                      <Text size='small'>Administração</Text>

                      <Box gap={ size === 'small' ? 'medium' : 'small' }>
                        {optionsMenu
                          .filter(a => a.location === 'adm')
                          .map(item => (
                            <Anchor
                              key={item.label}
                              label={item.label}
                              color='brand'
                              size='small'
                              onClick={() => history.push(item.click)}
                            />
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              }
            />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default DropModule;
