import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextInput, Box, Select, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { TitleField, SelectSearch } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { changeValues } from '../../../../actions/form';
import { searchLegislativeProcesses, clearFieldsFormSearch, hideLayer, setDefaultPageRules } from '../../actions/form-search';

import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchLegislativeProcesses, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchLegislativeProcesses(formParams);
    hideLayer();
  };

  render() {
    const {
      documentTypeOptions,
      statusLegislativeProcessOptions,
      interestLegislativeProcessOptions,

      type,
      status,
      areaInterest,
      year,
      number,
      menu,
      region,
      toggleLayer,

      hideLayer,
      clearFieldsFormSearch,
      changeValues,
    } = this.props;

    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Filtrar' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={hideLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  {/* Tipo */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Tipo' />

                      {type && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeValues({ fieldName: 'type', value: null }, 'changeFormSearchField')
                        }
                      />)}
                    </Box>

                    <Select
                      options={documentTypeOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={type ?? ''}
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) =>
                        changeValues({ fieldName: 'type', value: nextValue }, 'changeFormSearchField')
                      }
                    />
                  </Box>

                  {/* Status */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Status' />

                      {status && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeValues({ fieldName: 'status', value: null }, 'changeFormSearchField')
                        }
                      />)}
                    </Box>

                    <SelectSearch
                      options={statusLegislativeProcessOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={status ?? ''}
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) =>
                        changeValues({ fieldName: 'status', value: nextValue }, 'changeFormSearchField')
                      }
                    />
                  </Box>

                  {/* Área de interesse */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Área de interesse' />

                      {areaInterest && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeValues({ fieldName: 'areaInterest', value: null }, 'changeFormSearchField')
                        }
                      />)}
                    </Box>

                    <SelectSearch
                      options={interestLegislativeProcessOptions}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      value={areaInterest ?? ''}
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) =>
                        changeValues({ fieldName: 'areaInterest', value: nextValue }, 'changeFormSearchField')
                      }
                    />
                  </Box>

                  {/* Região */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Região (bairro, cidade)' />

                    <TextInput
                      value={region ?? ''}
                      maxLength={50}
                      onChange={event =>
                        changeValues({ fieldName: 'region', value: event.target.value }, 'changeFormSearchField')
                      }
                    />
                  </Box>

                  {/* Ano/Número */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box
                      direction='row'
                      gap='small'
                    >
                      <Box width='large'>
                        <TitleField text='Ano' />

                        <TextInput
                          type='number'
                          value={year ?? ''}
                          onChange={event =>
                            changeValues({ fieldName: 'year', value: event.target.value }, 'changeFormSearchField')
                          }
                        />
                      </Box>

                      <Box width='large'>
                        <TitleField text='Número' />

                        <TextInput
                          type='number'
                          value={number ?? ''}
                          onChange={event =>
                            changeValues({ fieldName: 'number', value: event.target.value }, 'changeFormSearchField')
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Assunto */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Assunto' />

                    <TextInput
                      value={menu ?? ''}
                      maxLength={50}
                      onChange={event =>
                        changeValues({ fieldName: 'menu', value: event.target.value }, 'changeFormSearchField')
                      }
                    />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() => clearFieldsFormSearch()}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          type,
                          status,
                          areaInterest,
                          year,
                          number,
                          menu,
                          region,
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ legislativeProcessesReducer: { formSearch }, selectOptionsReducer, layerReducer }) => ({
  documentTypeOptions: selectOptionsReducer.documentTypeOptions,
  statusLegislativeProcessOptions: selectOptionsReducer.statusLegislativeProcessOptions,
  interestLegislativeProcessOptions: selectOptionsReducer.interestLegislativeProcessOptions,

  type: formSearch.type,
  status: formSearch.status,
  areaInterest: formSearch.areaInterest,
  year: formSearch.year,
  number: formSearch.number,
  menu: formSearch.menu,
  region: formSearch.region,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchLegislativeProcesses,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeValues,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
