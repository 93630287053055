import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Box, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { TitleField, SelectSearch } from '../../../../components/form';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { changeValues } from '../../../../actions/form';
import { changeStatus, toggleFormChangeStatusLayer } from '../../actions/form-change-status';

class FormChangeStatus extends Component {
  handlePost = formParams => {
    const {
      changeStatus,
    } = this.props;

    changeStatus({ formParams });
  };

  render() {
    const {
      statusLegislativeProcessOptions,

      id,
      status,
      type,
      number,
      year,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      toggleFormChangeStatusLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'changeStatus') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormChangeStatusLayer}
              onEsc={toggleFormChangeStatusLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Trocar status' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleFormChangeStatusLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  <SimpleDetail
                    title='Tipo'
                    value={type}
                  />

                  <SimpleDetail
                    title='Número'
                    value={number}
                  />

                  <SimpleDetail
                    title='Ano'
                    value={year}
                  />

                  <Hr />

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Status' required />

                    <SelectSearch
                      options={statusLegislativeProcessOptions}
                      value={status ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) =>
                        changeValues({ fieldName: 'status', value: nextValue }, 'changeFormStatusField')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='status' />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormChangeStatusLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Salvar' : 'Salvando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          status,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ legislativeProcessesReducer: { formChangeStatus }, selectOptionsReducer, layerReducer }) => ({
  statusLegislativeProcessOptions: selectOptionsReducer.statusLegislativeProcessOptions,

  id: formChangeStatus.id,
  status: formChangeStatus.status,
  type: formChangeStatus.type,
  number: formChangeStatus.number,
  year: formChangeStatus.year,

  loadingPostForm: formChangeStatus.loadingPostForm,
  postDataError: formChangeStatus.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeValues,
    changeStatus,
    toggleFormChangeStatusLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormChangeStatus);
