const rulesName = {
  exportCall: 'export_call',
  editCall: 'edit_call',
  deleteCall: 'delete_call',
  createCall: 'create_call',
  cancelCampaign: 'cancel_campaign',
  createCampaign: 'create_campaign',
  exportAppointment: 'export_appointment',
  printAppointment: 'print_appointment',
  editAppointment: 'edit_appointment',
  createAppointment: 'create_appointment',
  printTreatment: 'print_search_treatment',
  exportTreatment: 'export_treatment',
  createCommetTreatment: 'create_commet_treatment',
  deleteTreatment: 'delete_treatment',
  editTreatment: 'edit_treatment',
  createTreatment: 'create_treatment',
  printVoter: 'print_voter',
  createLabel: 'create_label',
  exportVoter: 'export_voter',
  deleteVoter: 'delete_voter',
  editVoter: 'edit_voter',
  createVoter: 'create_voter',
  createProposition: 'create_legislative_process',
  deleteProposition: 'delete_legislative_process',
  editProposition: 'edit_legislative_process',
  printProposition: 'print_legislative_process',
  commentProposition: 'comment_legislative_process',

  createExpense: 'create_expense',
  deleteExpense: 'delete_expense',
  editExpense: 'edit_expense',
  printExpense: 'print_expense',

  createSurvey: 'create_survey',
  editSurvey: 'edit_survey',
  deleteSurvey: 'delete_survey',

  statusManifestation: 'status_manifestation',
  deleteManifestation: 'delete_manifestation',
  printManifestation: 'print_manifestation',

  accessAnalytic: 'access_analytic',
  accessElection: 'access_election',
};

export default rulesName;
