import React from 'react';
import { withRouter } from 'react-router-dom';
import { ResponsiveContext, Box, Text, Button } from 'grommet';
import { Edit } from 'grommet-icons';

import urlRoutes from '../../../../config/url-routes';

function SearchResult(props) {
  const configsService = [
    {
      name: 'Status',
      description: 'Os status permitem uma gestão eficaz dos atendimentos.',
      url: urlRoutes.settingsServiceStatusUrl,
    },
    {
      name: 'Origem',
      description: 'As origens ajudam a informar por qual meio os atendimentos são cadastrados.',
      url: urlRoutes.settingsOriginUrl,
    },
    {
      name: 'Categoria',
      description: 'As categorias são importantes para segmentar os atendimentos.',
      url: urlRoutes.settingsCategoryUrl,
    },
    {
      name: 'Escritórios',
      description: 'Os escritórios são úteis para definir de qual local o atendimento originou.',
      url: urlRoutes.settingsOfficeUrl,
    }
  ];

  const configsVoter = [
    {
      name: 'Grupos',
      description: 'Os grupos são importantes para segmentar os eleitores cadastrados.',
      url: urlRoutes.settingsGroupUrl,
    }
  ];

  const configsDocument = [
    {
      name: 'Status',
      description: 'Os status permitindo uma gestão eficaz das proposituras.',
      url: urlRoutes.settingsLegislativeProcesseStatusUrl,
    }
  ];


  const configsUser = [
    {
      name: 'Usuários',
      description: 'Cadastre, altere e inative',
      url: urlRoutes.settingsUserUrl,
    }
  ];


  const redirect = (url) => {
    const { history } = props;
    history.push(url);
  };

  const renderItemConfig = ({ name, description, url }, size, i) => (
    <Box
      key={i}
      align='center'
      direction='row'
      pad={{
        horizontal: size === 'small' ? 'large' : 'medium',
        vertical: size === 'small' ? 'medium' : 'xsmall',
      }}
      border={{
        color: 'light-3',
        side: 'top',
      }}
      justify='between'
      gap={ size === 'small' ? 'medium' : 'small'}
    >
      <Box
        direction={
          size === 'xsmall' || size === 'small' ? 'column' : 'row'
        }
        justify='between'
        gap={ size === 'small' ? 'medium' : 'large' }
      >
        <Box
          width={
            size === 'xsmall' || size === 'small' ? '100vw' : '10vw'
          }
        >
          <Text color='dark-1' size='small'>
            {name}
          </Text>
        </Box>

        <Box width={
          size === 'xsmall' || size === 'small' ? '100vw' : '40vw'
        }>
          <Text color='dark-3' size='small'>
            {description}
          </Text>
        </Box>
      </Box>

      <Button
        title='Editar'
        size='small'
        icon={<Edit color='brand' size='medium' />}
        onClick={() => redirect(url) }
      />
    </Box>
  );

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width={ size === 'small' || size === 'medium' ? '100vw' : '70vw' }
              gap={ size === 'small' ? 'medium' : 'xsmall' }
            >
              <Box
                margin={{ bottom: 'medium' }}
              >
                <Text size='large'>Personalize o sistema</Text>
              </Box>


              { /* Atendimento */}
              <Box
                round='xsmall'
                background='white'
                border={{ color: 'light-4' }}
                margin={{ bottom: 'large' }}
              >
                <Box
                  pad={{
                    horizontal: size === 'small' ? 'large' : 'medium',
                    vertical: 'medium',
                  }}
                >
                  <Text size='medium' color='dark-1'>Atendimento</Text>
                </Box>

                {configsService.map((row, i) => {
                  return renderItemConfig(
                    {
                      name: row.name,
                      description: row.description,
                      url: row.url,
                    }, size, i
                  );
                })}
              </Box>


              { /* Eleitor */}
              <Box
                round='xsmall'
                background='white'
                border={{ color: 'light-4' }}
                margin={{ bottom: 'large' }}
              >
                <Box
                  pad={{
                    horizontal: size === 'small' ? 'large' : 'medium',
                    vertical: 'medium',
                  }}
                >
                  <Text size='medium' color='dark-1'>Eleitor</Text>
                </Box>

                {configsVoter.map((row, i) => {
                  return renderItemConfig(
                    {
                      name: row.name,
                      description: row.description,
                      url: row.url,
                    }, size, i
                  );
                })}
              </Box>


              { /* Eleitor */}
              <Box
                round='xsmall'
                background='white'
                border={{ color: 'light-4' }}
                margin={{ bottom: 'large' }}
              >
                <Box
                  pad={{
                    horizontal: size === 'small' ? 'large' : 'medium',
                    vertical: 'medium',
                  }}
                >
                  <Text size='medium' color='dark-1'>Docs. proposituras, Adm.</Text>
                </Box>

                {configsDocument.map((row, i) => {
                  return renderItemConfig(
                    {
                      name: row.name,
                      description: row.description,
                      url: row.url,
                    }, size, i
                  );
                })}
              </Box>


              { /* Usuario */}
              <Box
                round='xsmall'
                background='white'
                border={{ color: 'light-4' }}
                margin={{ bottom: 'large' }}
              >
                <Box
                  pad={{
                    horizontal: size === 'small' ? 'large' : 'medium',
                    vertical: 'medium',
                  }}
                >
                  <Text size='medium' color='dark-1'>Usuário</Text>
                </Box>

                {configsUser.map((row, i) => {
                  return renderItemConfig(
                    {
                      name: row.description,
                      description: '',
                      url: row.url,
                    }, size, i
                  );
                })}
              </Box>

            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

export default withRouter(SearchResult);
