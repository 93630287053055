import TYPES from './types';
import { listVoter } from '../../../api/voter';
import { downloadCsv } from '../../../components';
import { formattedDateTime, anonymize } from '../../../utils';
import * as analytics from '../../../analytics';

const _notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const _command = params =>
  dispatch => {
    dispatch(_notification('Gerando Excel. Aguarde...'));

    listVoter(params)
      .then(({ data: { data } }) => {
        downloadCsv({
          data,
          specifications: {
            id: { name: 'Ref. eleitor', transform: (value) => value },
            individualRegistration: {
              name: 'CPF',
              transform: (value) => value ? anonymize(value, /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/g, '$1.XXX.XXX-$4') : ''
            },
            name: { name: 'Nome', transform: (value) => value },
            nickName: { name: 'Apelido', transform: (value) => value ?? '' },
            genreName: { name: 'Genero', transform: (value) => value ?? '' },
            dateOfBirth: { name: 'Nascimento', transform: (value) => value ?? '' },
            telephone: { name: 'Telefone(s)', transform: (value) => value ?? '' },
            group: { name: 'Grupo(s)', transform: (value) => value ?? '' },
            address: { name: 'Logradouro', transform: (value) => value ?? '' },
            addressNumber: { name: 'Número', transform: (value) => value ?? '' },
            additionalAddress: { name: 'Complemento', transform: (value) => value ?? '' },
            districtName: { name: 'Bairro', transform: (value) => value ?? '' },
            cityName: { name: 'Cidade', transform: (value) => value ?? '' },
            stateName: { name: 'Estado', transform: (value) => value ?? '' },
            zipcode: { name: 'CEP', transform: (value) => value ?? '' },
            note: { name: 'Observações', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
            email: { name: 'E-mail', transform: (value) => value ?? '' },
            schoolName: { name: 'Local votação', transform: (value) => value ?? '' },
            electoralZone: { name: 'Zona eleitoral', transform: (value) => value ?? '' },
            votingSection: { name: 'Seção eleitoral', transform: (value) => value ?? '' },
            status: { name: 'Status', transform: (value) => value ? 'Ativo' : 'Inativo' },
            createdAt: { name: 'Cadastro', transform: (value) => value ?? '' },
            updatedAt: { name: 'Atualização', transform: (value) => value ?? '' },
          },
          fileName: `listagem-eleitores-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.csv`,
        })
      })
      .catch(() =>
        dispatch(_notification('Ops... não conseguimos gerar o Excel.'))
      );
  }

export const generateCsv = params => {
  //ga
  analytics.registerEvent({
    category: analytics.categories.voter,
    action: analytics.actions.voterExport,
  });

  return _command(params);
};
