import TYPES from './types';
import { list } from '../../../api/common';
import { downloadCsv } from '../../../components';
import { formattedDateTime } from '../../../utils';

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const generateCsv = params =>
  dispatch => {
    dispatch(notification('Gerando Excel. Aguarde...'));

    list(params, 'telefonema.php')
      .then(({ data: { data } }) => {
        downloadCsv({
          data,
          specifications: {
            id: { name: 'Ref.', transform: (value) => `LI-${value}` },
            subject: { name: 'Assunto', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
            createdBy: { name: 'Cadastrado por', transform: (value) => value },
            createdAt: { name: 'Data cadastro', transform: (value) => value },
            name: { name: 'Quem ligou', transform: (value) => value },
            telephone: { name: 'Telefone de quem ligou', transform: (value) => value },
            status: { name: 'Status', transform: (value) => value },
          },
          fileName: `listagem-ligacoes-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.csv`,
        })
      })
      .catch(() =>
        dispatch(notification('Ops... não conseguimos gerar o Excel.'))
      );
  }
