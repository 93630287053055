import TYPES from './types';
import { listServicePrint } from '../../../api/service';
import { formattedDateTime } from '../../../utils';
import { saveAs } from 'file-saver';

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const generatePrintList = params =>
  dispatch => {
    dispatch(notification('Gerando arquivo. Aguarde...'));

    listServicePrint(params)
      .then(response => {
        saveAs(
          response.data,
          `listagem-atendimentos-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.pdf`
        );
      })
      .catch(() =>
        dispatch(notification('Ops... não conseguimos gerar o arquivo.'))
      );
  }
