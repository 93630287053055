import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, TextArea, Anchor, TextInput, Text, Box, Select } from 'grommet';
import { Close, Share, FormClose } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleDetail } from '../../../../components/detail';
import { TitleField, CalendarDropButton } from '../../../../components/form';

import { searchPolls } from '../../actions/form-search';
import { update, create, changeField, hideFormManagerLayer } from '../../actions/form-manager';

import urlsApp from '../../../../config/url-routes';
import selectColorOptions from '../../../../constants/select-color-options';
import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormCreate extends Component {
  handlePost = formParams => {
    const { isEditing, create, update } = this.props;

    if(isEditing && formParams.id) {
      update({ formParams });
    }
    else {
      create({
        formParams,
        callbackSuccess: () => {
          const {
            searchPolls,
            hideFormManagerLayer,
          } = this.props;

          searchPolls();
          hideFormManagerLayer();
        }
      });
    }
  };

  render() {
    const {
      id,
      question,
      thanksText,
      endDate,
      color,
      options,

      getDataFormEditError,
      isEditing,
      toggleLayer,
      loadingPostForm,
      postDataError,

      changeField,
      hideFormManagerLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'manager') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideFormManagerLayer}
              onEsc={hideFormManagerLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                {isEditing && !id && !getDataFormEditError ? (
                  <Placeholder
                    title='Carregando...'
                    message='Aguarde que estamos preparando as coisas :)'
                  />
                ) : null}

                {getDataFormEditError ? (
                  <Placeholder
                    title='Ops! Algo deu errado :('
                    message='Estamos constrangidos por isso.'
                    button={{
                      label: 'Fechar',
                      action: hideFormManagerLayer,
                    }}
                  />
                ) : null}

                {!getDataFormEditError && ((isEditing && id) || !isEditing) ? (
                  <>
                    <Box
                      direction='row'
                      justify='between'
                      align='baseline'
                      margin={{ horizontal: 'large' }}
                    >
                      <TitleDetail value={isEditing && id ? 'Editar enquete' : 'Nova enquete'} />

                      <Button
                        plain
                        title='Fechar'
                        icon={<FormClose />}
                        onClick={hideFormManagerLayer}
                      />
                    </Box>


                    <Box flex overflow='auto'>
                      {/* pergunta */}
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Digite a pergunta' required />

                        <TextArea
                          rows={2}
                          disabled={isEditing}
                          resize='vertical'
                          maxLength={200}
                          value={question ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'question', value: event.target.value }, 'changeFormManagerField')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='question' />
                      </Box>


                      {/* Opções */}
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <Box
                          round='xsmall'
                          border={{ color: 'light-4' }}
                          background='white'
                        >
                          {options && options.map((item, index) => {
                            return (
                              <Box
                                key={index}
                                align='center'
                                direction='row'
                                pad={{
                                  vertical: size === 'small' ? 'large' : 'xxsmall',
                                  horizontal: size === 'small' ? 'large' : 'xxsmall',
                                }}
                                border={{ color: 'light-3', side: 'bottom' }}
                              >
                                <TextInput
                                  plain
                                  value={item.op ?? ''}
                                  placeholder='Opção *'
                                  maxLength={50}
                                  disabled={isEditing}
                                  onChange={event =>
                                    changeField(
                                      {
                                        nested: 'options',
                                        fieldName: 'op',
                                        value: event.target.value,
                                        index
                                      },
                                      'changeFormManagerField'
                                    )
                                  }
                                />

                                <Anchor
                                  icon={<Close size='small' />}
                                  color='dark-3'
                                  title='Remover'
                                  disabled={isEditing}
                                  onClick={() =>
                                    changeField(item, 'removeOptFormManager')
                                  }
                                />
                              </Box>
                            )
                          })}

                          <Box
                            pad={{
                              vertical: size === 'small' ? 'large' : 'small'
                            }}
                            align='center'
                            flex={{ shrink: 0 }}
                          >
                            <Anchor
                              label='Adicionar resposta'
                              size='small'
                              disabled={isEditing || options.length > 5}
                              onClick={() =>
                                changeField({ id: null, op: null }, 'addOptFormManager')
                              }
                            />
                          </Box>
                        </Box>

                        <ErrorForm errorsList={postDataError.errors} fieldName='options' />
                      </Box>


                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />


                      {/* Encerramento/cor fundo pagina */}
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <Box direction='row' gap='small'>
                          <Box width='large'>
                            <TitleField text='Data de encerramento' required />

                            <CalendarDropButton
                              date={endDate ?? ''}
                              title='Informe a data prevista para o reembolso dessa despesa'
                              onSelect={(selectedDate) => changeField({ fieldName: 'endDate', value: selectedDate }, 'changeFormManagerField')}
                            />

                            <ErrorForm errorsList={postDataError.errors} fieldName='endDate' />
                          </Box>

                          <Box width='large'>
                            <TitleField text='Cor de fundo da página' required />

                            <Select
                              options={selectColorOptions}
                              value={color ?? ''}
                              labelKey='name'
                              valueKey={{ key: 'id', reduce: true }}
                              emptySearchMessage='Selecione uma opção'
                              onChange={({ value: nextValue }) => changeField({ fieldName: 'color', value: nextValue }, 'changeFormManagerField') }
                            >
                              {({ id, name }) => {
                                return (
                                  <Box
                                    pad={{ horizontal: 'small', vertical: 'small' }}
                                    direction='row'
                                    gap='small'
                                    align='center'
                                  >
                                    <Box
                                      width='1em'
                                      height='1em'
                                      background={id}
                                      round='xsmall'
                                      elevation='xsmall'
                                    />

                                    <Text size='medium' color='dark-1'>{name}</Text>
                                  </Box>
                                )
                              }}
                            </Select>

                            <ErrorForm errorsList={postDataError.errors} fieldName='color' />
                          </Box>
                        </Box>
                      </Box>


                      {/* agradecimento */}
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <TitleField text='Digite uma mensagem de agradecimento' />

                        <TextArea
                          rows={2}
                          resize='vertical'
                          maxLength={100}
                          value={thanksText ?? ''}
                          onChange={event =>
                            changeField({ fieldName: 'thanksText', value: event.target.value }, 'changeFormManagerField')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='thanksText' />
                      </Box>


                      <Box
                        border={{ color: 'light-3', side: 'bottom' }}
                        margin={{ top: 'medium', bottom: 'medium' }}
                      />


                      {/* termo */}
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                      >
                        <Text size='medium' color='dark-3'>
                          Ao clicar em "Salvar", você concorda com os <Anchor href={urlsApp.pollTerms} target='_blank'>Termos de uso <Share color='brand' size='small' /></Anchor>  da ferramenta de enquetes.
                        </Text>
                      </Box>
                    </Box>


                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                      align='center'
                      gap='small'
                    >
                      <Box width='xsmall'>
                        <Anchor
                          color='dark-3'
                          label='Cancelar'
                          onClick={hideFormManagerLayer}
                        />
                      </Box>

                      <Box width='small'>
                        <Button
                          color='brand'
                          primary
                          label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                          disabled={ loadingPostForm }
                          onClick={() =>
                            this.handlePost({
                              id,
                              question,
                              thanksText,
                              endDate: formattedDateTime(endDate),
                              color,
                              options,
                            })
                          }
                        />
                      </Box>

                    </Box>
                  </>
                ) : null}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ surveyReducer: { formManager }, layerReducer }) => ({
  id: formManager.id,
  question: formManager.question,
  thanksText: formManager.thanksText,
  endDate: formManager.endDate,
  color: formManager.color,
  options: formManager.options,

  getDataFormEditError: formManager.getDataFormEditError,
  isEditing: formManager.isEditing,
  loadingPostForm: formManager.loadingPostForm,
  postDataError: formManager.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    update,
    create,
    changeField,
    searchPolls,
    hideFormManagerLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
