import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Text, Button, Anchor } from 'grommet';

import { EmptySearches, Placeholder, ErrorsSearches } from '../../../../components';
import { showFormManagerLayer } from '../../actions/form-manager';

import urlRoutes from '../../../../config/url-routes';

function SearchResult(props) {
  const {
    history,

    loadingFormSearch,
    dataRows,
    getListError,

    showFormManagerLayer,
  } = props;

  if(loadingFormSearch && !getListError) {
    return (
      <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(getListError) {
    return <ErrorsSearches />;
  }

  if(!dataRows.length) {
    return <EmptySearches />;
  }

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            justify='center'
            margin={{ horizontal: 'medium', top: '80px' }}
          >
            <Box
              pad='small'
              width={ size === 'small' || size === 'medium' ? '100vw' : '70vw' }
              gap={ size === 'small' ? 'medium' : 'xsmall'}
            >
              <Box
                direction='row'
                align='center'
                justify='between'
                margin={{ bottom: 'medium' }}
              >
                <Text size='large'>Escritórios</Text>

                <Anchor
                  size='small'
                  onClick={ () => history.push(urlRoutes.settingsUrl) }
                  label='voltar para configurações'
                />
              </Box>

              <Box
                round='xsmall'
                border={{ color: 'light-4' }}
                background='white'
                elevation='xsmall'
              >
                <Box
                  align='center'
                  pad={{ horizontal: size === 'small' ? 'large' : 'medium', vertical: size === 'small' ? 'large' : 'medium' }}
                  border={{ color: 'light-4', style: 'dashed', side: 'bottom' }}
                  round={{ size: 'small', corner: 'top' }}
                  background='light-1'
                  direction='row'
                  justify='between'
                >
                  <Text size='medium' color='dark-6' weight={300}>
                    {dataRows.length} encontrados
                  </Text>

                  <Button
                    primary
                    size='small'
                    label='+ Adicionar'
                    color='dark-2'
                    onClick={() => { showFormManagerLayer() }}
                  />
                </Box>

                {dataRows.map((row, i) => {
                  const isLastLine = dataRows.length === i + 1;

                  return (
                    <Box
                      key={row.id}
                      align='center'
                      direction='row'
                      pad={{ horizontal: size === 'small' ? 'large' : 'medium', vertical: size === 'small' ? 'medium' : 'small' }}
                      border={ !isLastLine ? { color: 'light-3', side: 'bottom' } : null }
                      justify='between'
                    >
                      <Box gap='xsmall'>
                        <Text color='dark-2' size='medium'>{row.name}</Text>
                        <Text color='dark-4' size='small'>{row.description}</Text>
                      </Box>

                      <Box direction='row' align='center' gap='small'>
                        <Button
                          size='small'
                          label='Editar'
                          color='light-5'
                          onClick={() => { showFormManagerLayer(row) }}
                        />

                        {/*<Button
                          title={row.votersUsing ? 'Remova os atendimentos vinculados antes de excluir' : 'Excluir'}
                          disabled={!!row.votersUsing}
                          icon={<Trash color='status-error' size='medium' />}
                          onClick={() => { toggleFormDeleteLayer(row) }}
                        />*/}
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ settingsOfficeReducer: { searchResult } }) => ({
  loadingFormSearch: searchResult.loadingFormSearch,
  dataRows: searchResult.dataRows,
  getListError: searchResult.getListError,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    // toggleFormDeleteLayer,
    showFormManagerLayer,
  }, dispath)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
