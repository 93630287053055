import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Details from '../components/details/details';
import FormSearch from '../components/form-search/form-search';
import FormDelete from '../components/form-delete/form-delete';
import FormChangeStatus from '../components/form-change-status/form-change-status';
import FormComment from '../components/form-comment/form-comment';
import FormReminder from '../components/form-reminder/form-reminder';
import FormCreate from '../components/form-manager/form-manager';
import FormPrepareDoc from '../components/form-prepare-doc/form-prepare-doc';
import SearchResult from '../components/search-result/search-result';
import Communication from '../components/communication/communication';
import FormCreateArchive from '../components/form-archive/form-archive';
import Header from '../../../components/header/header';

import { searchLegislativeProcesses, setDefaultPageRules } from '../actions/form-search';
import {
  getUsersOptions,
  getDocumentTypeOptions,
  getStatusLegislativeProcess,
  getAreaInterestLegislativeProcess,
} from '../../../actions/select-options';

class LegislativeProcess extends Component {
  componentDidMount() {
    const {
      getUsersOptions,
      getDocumentTypeOptions,
      getStatusLegislativeProcess,
      getAreaInterestLegislativeProcess,

      searchLegislativeProcesses,
      setDefaultPageRules,
    } = this.props;

    getUsersOptions();
    getDocumentTypeOptions();
    getStatusLegislativeProcess();
    getAreaInterestLegislativeProcess();

    setDefaultPageRules();
    searchLegislativeProcesses();
  }

  render() {
    const {
      userLogged,
    } = this.props;

    return (
      <>
        <Header userLogged={userLogged} />
        <SearchResult />

        <FormSearch />
        <FormDelete />
        <FormChangeStatus />
        <FormComment />
        <FormReminder />
        <FormCreate />
        <FormPrepareDoc />
        <Details />
        <FormCreateArchive />
        <Communication />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer: { authenticated } }) => ({
  userLogged: authenticated,
});

const mapDispatchToProps = dispath => {
  return bindActionCreators({
    getUsersOptions,
    getDocumentTypeOptions,
    getStatusLegislativeProcess,
    getAreaInterestLegislativeProcess,
    searchLegislativeProcesses,
    setDefaultPageRules,
  }, dispath)
};

export default connect(mapStateToProps, mapDispatchToProps)(LegislativeProcess);
