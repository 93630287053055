import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, Box, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { TitleDetail } from '../../../../components/detail';
import { formattedDateTime } from '../../../../utils';

import { cancel, toggleFormCancelLayer } from '../../actions/form-cancel';

import visibleWidth from '../../../../constants/layer-width';

class FormCancel extends Component {
  handlePost = formParams => {
    const {
      cancel,
    } = this.props;

    cancel({ formParams });
  };

  render() {
    const {
      id,
      name,
      dateSend,
      toggleLayer,

      loadingPostForm,
      postDataError,

      toggleFormCancelLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'changeStatus') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormCancelLayer}
              onEsc={toggleFormCancelLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Cancelar campanha' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleFormCancelLayer}
                  />
                </Box>

                <Box flex overflow='auto'>
                  <Box
                    flex={{ shrink: 0 }}
                    margin={{ horizontal: 'large' }}
                    gap='xsmall'
                  >
                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Nome</Text>
                      <Text size='medium' color='dark-4'>{name}</Text>
                    </Box>

                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Data de envio</Text>
                      <Text size='medium' color='dark-4'>{formattedDateTime(dateSend, 'DD MMM YYYY')}</Text>
                    </Box>

                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Horário de envio</Text>
                      <Text size='medium' color='dark-4'>{formattedDateTime(dateSend, 'HH:mm')}</Text>
                    </Box>

                    <Box
                      margin={{ top: 'medium' }}
                    >
                      <Text
                        size='small'
                        textAlign='start'
                        color='status-warning'
                      >
                        Ao cancelar esta campanha o disparo não será realizado e o valor pago não será estornado.
                      </Text>
                    </Box>

                    <Box
                      flex={{ shrink: 0 }}
                    >
                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>
                  </Box>
                </Box>

                {/* botoes acao */}
                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Não'
                      onClick={toggleFormCancelLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Sim' : 'Cancelando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          status: 6,
                        })
                      }
                    />
                  </Box>
                </Box>


              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ campaignsReducer: { cancel }, layerReducer }) => ({
  id: cancel.id,
  name: cancel.name,
  dateSend: cancel.dateSend,

  loadingPostForm: cancel.loadingPostForm,
  postDataError: cancel.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    cancel,
    toggleFormCancelLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormCancel);
