import TYPES from './types';
import { list } from '../../../api/common';
import { downloadCsv } from '../../../components';
import { formattedDateTime } from '../../../utils';
import * as analytics from '../../../analytics';

const _notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const _command = params =>
  dispatch => {
    dispatch(_notification('Gerando Excel. Aguarde...'));

    list(params, 'legislative.processe.php')
      .then(({ data: { data } }) => {
        downloadCsv({
          data,
          specifications: {
            type: { name: 'Tipo', transform: (value) => value ?? '' },
            number: { name: 'Número', transform: (value) => value ?? '' },
            year: { name: 'Ano', transform: (value) => value ?? '' },
            proceeding: { name: 'Em tramitação', transform: (value) => value ? 'Sim' : 'Não' },
            status: { name: 'Status', transform: (value) => value ?? '' },
            areaInterest: { name: 'Área de interesse', transform: (value) => value ?? '' },
            region: { name: 'Região', transform: (value) => value ?? '' },
            menu: { name: 'Assunto', transform: (value) => value ? value.replace(/\n/gm, '') : '' },
            createdAt: { name: 'Cadastro', transform: (value) => value ?? '' },
            updatedAt: { name: 'Atualização', transform: (value) => value ?? '' },
          },
          fileName: `listagem-documento-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.csv`,
        })
      })
      .catch(() =>
        dispatch(_notification('Ops... não conseguimos gerar o Excel.'))
      );
  }

export const generateCsv = params => {
  //ga
  analytics.registerEvent({
    category: analytics.categories.legislative,
    action: analytics.actions.legislativeExport,
  });

  return _command(params);
};
