import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Select, Button, Layer, TextArea, Box, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { TitleField, CalendarDropButton } from '../../../../components/form';
import { formattedDateTime } from '../../../../utils';
import { TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { createComment, changeField, toggleFormCommentLayer } from '../../actions/form-comment';

class FormComment extends Component {
  handlePost = formParams => {
    const {
      createComment,
    } = this.props;

    createComment({ formParams });
  };

  render() {
    const {
      usersOptions,

      treatmentId,
      reference,
      date,
      responsibleId,
      comment,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeField,
      toggleFormCommentLayer,
    } = this.props;

    if(toggleLayer && toggleLayer === 'createComment') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormCommentLayer}
              onEsc={toggleFormCommentLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value={`Comentar atendimento ${reference}`} />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleFormCommentLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Responsável' required />

                    <Select
                      options={usersOptions.filter(a => a.status)}
                      value={responsibleId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) =>
                        changeField({ fieldName: 'responsibleId', value: nextValue }, 'changeFormCommentField')
                      }
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='responsibleId' />
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Data' required />

                    <CalendarDropButton
                      date={date ?? ''}
                      onSelect={(selectedDate) => changeField({ fieldName: 'date', value: selectedDate }, 'changeFormCommentField')}
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='date' />
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Comentário' required />

                    <TextArea
                      rows={3}
                      resize='vertical'
                      maxLength={1000}
                      value={comment ?? ''}
                      placeholder='Exemplo: Entramos em contato com a prefeitura e a mesma esta analisando.'
                      onChange={event => changeField({ fieldName: 'comment', value: event.target.value }, 'changeFormCommentField')}
                    />

                    <ErrorForm errorsList={postDataError.errors} fieldName='comment' />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormCommentLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ? 'Salvar' : 'Salvando...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          treatmentId,
                          date: formattedDateTime(date),
                          responsibleId,
                          comment,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ servicesReducer: { formComment }, layerReducer, selectOptionsReducer }) => ({
  usersOptions: selectOptionsReducer.usersOptions,

  treatmentId: formComment.treatmentId,
  reference: formComment.reference,
  date: formComment.date,
  responsibleId: formComment.responsibleId,
  comment: formComment.comment,

  loadingPostForm: formComment.loadingPostForm,
  postDataError: formComment.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeField,
    createComment,
    toggleFormCommentLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormComment);
