import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ResponsiveContext,
  Button,
  Layer,
  TextInput,
  Box,
  Anchor,
  RadioButtonGroup,
  Text
} from 'grommet';
import { FormClose } from 'grommet-icons';

import { TitleField, DescriptionField, CalendarDropButton } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchPhonecalls, clearFieldsFormSearch, hideLayer, setDefaultPageRules, changeField } from '../../actions/form-search';

import paginationDefault from '../../../../constants/pagination';
import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

import selectPhonecallStatusOptions from '../../../../constants/select-phonecall-status-options';

class FormSearch extends Component {
  searchAndSetPagination = formParams => {
    const { searchPhonecalls, hideLayer, setDefaultPageRules } = this.props;

    setDefaultPageRules();
    searchPhonecalls(formParams);
    hideLayer();
  };

  render() {
    const {
      reference,
      name,
      telephone,
      subject,
      status,
      startDate,
      endDate,
      toggleLayer,

      hideLayer,
      clearFieldsFormSearch,
      changeField,
    } = this.props;

    if(toggleLayer && toggleLayer === 'search') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={hideLayer}
              onEsc={hideLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Filtrar' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={hideLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  {/* Referencia */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Referência' />

                    <TextInput
                      value={reference ?? ''}
                      onChange={event => changeField({ fieldName: 'reference', value: event.target.value })}
                    />
                  </Box>


                  {/* Nome */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    direction={ size === 'small' ? 'column' : 'row' }
                    align='stretch'
                    flex={{ shrink: 0 }}
                    gap='small'
                  >
                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                    >
                      <TitleField text='Nome' />

                      <DescriptionField
                        text='Busque por um trecho do nome.'
                        />
                    </Box>

                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                    >
                      <TextInput
                        value={name ?? ''}
                        onChange={event => changeField({ fieldName: 'name', value: event.target.value })}
                      />
                    </Box>
                  </Box>


                  {/* Telefone */}
                  <Box
                    margin={{
                      vertical: 'small',
                      horizontal: 'large'
                    }}
                    direction={ size === 'small' ? 'column' : 'row' }
                    align='stretch'
                    flex={{ shrink: 0 }}
                    gap='small'
                  >
                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                    >
                      <TitleField text='Telefone' />

                      <DescriptionField
                        text='Busque por um trecho do telefone da pessoa.'
                        />
                    </Box>

                    <Box
                      width={ size === 'small' ? '100vw' : '50vw'}
                    >
                      <TextInput
                        value={telephone ?? ''}
                        onChange={event => changeField({ fieldName: 'telephone', value: event.target.value })}
                      />
                    </Box>
                  </Box>


                  {/* Data de cadastro */}
                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Data de cadastro' />

                    <Box
                      direction='row'
                      gap='small'
                    >
                      <CalendarDropButton
                        date={startDate ?? ''}
                        placeholder='De'
                        onSelect={(selectedDate) => changeField({ fieldName: 'startDate', value: selectedDate })}
                      />

                      <CalendarDropButton
                        date={endDate ?? ''}
                        placeholder='Até'
                        onSelect={(selectedDate) => changeField({ fieldName: 'endDate', value: selectedDate })}
                      />
                    </Box>
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <Box direction='row' align='center' gap='xsmall'>
                      <TitleField text='Status' />

                      {status && (<Anchor
                        size='small'
                        label='limpar'
                        onClick={() =>
                          changeField({ fieldName: 'status', value: null })
                        }
                      />)}
                    </Box>

                    <Box
                      round='xxsmall'
                      pad={{
                        vertical: 'small',
                      }}
                      gap='xsmall'
                    >
                      <RadioButtonGroup
                        name='status'
                        value={status ?? ''}
                        options={
                          selectPhonecallStatusOptions.map(({ name, id }) => ({
                            label: name,
                            id: `status_${id}`,
                            value: id.toString(),
                          }))
                        }
                        onChange={event => {
                          changeField({ fieldName: 'status', value: event.target.value })
                        }}
                      >
                        {(option, { checked }) => {
                          let background = null;
                          let borderColor = 'dark-5';

                          if(checked) {
                            background = 'brand';
                            borderColor = 'brand';
                          }

                          return (
                            <Box
                              direction='row'
                              gap='small'
                              justify='center'
                              align='center'
                            >
                              <Box
                                width={'20px'}
                                height={'20px'}
                                background={background}
                                round='xxsmall'
                                border={{
                                  color: borderColor
                                }}
                              />
                              <Text color='dark-2' size='small'>{option.label}</Text>
                            </Box>
                          )
                        }}
                      </RadioButtonGroup>
                    </Box>
                  </Box>

                  <Box
                    margin={{
                      vertical: size === 'small' ? 'medium' : 'small',
                      horizontal: 'large'
                    }}
                    flex={{ shrink: 0 }}
                  >
                    <TitleField text='Assunto' />

                    <TextInput
                      value={subject ?? ''}
                      onChange={event => changeField({ fieldName: 'subject', value: event.target.value })}
                    />
                  </Box>
                </Box>

                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  margin={{ horizontal: 'large' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='small'>
                    <Button
                      color='dark-3'
                      label='Limpar'
                      onClick={() => clearFieldsFormSearch()}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label='Buscar'
                      onClick={() =>
                        this.searchAndSetPagination({
                          status,
                          startDate: formattedDateTime(startDate),
                          endDate: formattedDateTime(endDate),
                          reference,
                          subject,
                          page: paginationDefault.page,
                          limit: paginationDefault.limit,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ phonecallsReducer: { formSearch }, layerReducer }) => ({
  reference: formSearch.reference,
  name: formSearch.name,
  telephone: formSearch.telephone,
  subject: formSearch.subject,
  status: formSearch.status,
  startDate: formSearch.startDate,
  endDate: formSearch.endDate,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    searchPhonecalls,
    clearFieldsFormSearch,
    setDefaultPageRules,
    hideLayer,
    changeField,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
