import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, Box, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { TitleDetail } from '../../../../components/detail';

import { searchPolls } from '../../actions/form-search';
import { remove, removeAnswer, toggleFormDeleteLayer } from '../../actions/form-delete';

import visibleWidth from '../../../../constants/layer-width';

import { formattedDateTime } from '../../../../utils';

class FormDelete extends Component {
  handlePost = formParams => {
    const { remove, removeAnswer, actionToDelete } = this.props;

    if(actionToDelete === 'survey') {
      remove({
        formParams,
        callbackSuccess: () => {
          const { searchPolls, toggleFormDeleteLayer } = this.props;

          searchPolls();
          toggleFormDeleteLayer();
        },
      });
    }
    else {
      removeAnswer({
        formParams,
        callbackSuccess: () => {
          const { toggleFormDeleteLayer } = this.props;
          toggleFormDeleteLayer();
        },
      });
    }
  };

  render() {
    const {
      id,
      reference,
      endDate,
      actionToDelete,

      toggleLayer,
      loadingPostForm,
      postDataError,
      toggleFormDeleteLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'delete') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormDeleteLayer}
              onEsc={toggleFormDeleteLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail
                    value={actionToDelete === 'survey' ?
                      'Remover enquete' :
                      'Remover todas as respostas da enquete' }
                  />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleFormDeleteLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  <Box
                    flex={{ shrink: 0 }}
                    margin={{ horizontal: 'large' }}
                    gap='xsmall'
                  >
                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Referência</Text>
                      <Text size='medium' color='dark-4'>{reference}</Text>
                    </Box>

                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Data de encerramento</Text>
                      <Text size='medium' color='dark-4'>{formattedDateTime(endDate)}</Text>
                    </Box>

                    <Box
                      margin={{ top: 'medium' }}
                    >
                      <Text
                        size='small'
                        textAlign='start'
                        color='status-warning'
                      >
                        {actionToDelete === 'survey' ?
                          'Após a confirmação a enquete será PERMANENTEMENTE excluida.' :
                          'Após a confirmação as respostas serão PERMANENTEMENTE excluidas.' }
                      </Text>
                    </Box>

                    <Box
                      flex={{ shrink: 0 }}
                    >
                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>
                  </Box>
                </Box>

                {/* botoes acao */}
                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Cancelar'
                      onClick={toggleFormDeleteLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='status-error'
                      primary
                      label={ !loadingPostForm ? 'SIM, Remover': 'Removendo...' }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          reference,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ surveyReducer: { remove }, layerReducer }) => ({
  id: remove.id,
  reference: remove.reference,
  endDate: remove.endDate,
  actionToDelete: remove.actionToDelete,

  loadingPostForm: remove.loadingPostForm,
  postDataError: remove.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    remove,
    removeAnswer,
    searchPolls,
    toggleFormDeleteLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormDelete);
