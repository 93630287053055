import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Text, TextArea, Anchor, TextInput, Box } from 'grommet';
import { Close, AddCircle, FormClose } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleField, SelectSearch } from '../../../../components/form';
import { TitleDetail } from '../../../../components/detail';

import { searchLegislativeProcesses } from '../../actions/form-search';
import { update, create, hideFormManagerLayer } from '../../actions/form-manager';
import { changeValues } from '../../../../actions/form';
import { getAuthorsOptions } from '../../../../actions/select-options';

import visibleWidth from '../../../../constants/layer-width';

import selectYesNoOptions from '../../../../constants/select-yesno-options';

function FormCreate(props) {
  const [name, setName] = useState('');

  const {
    documentTypeOptions,
    authorsOptions,
    statusLegislativeProcessOptions,
    interestLegislativeProcessOptions,

    id,
    menu,
    number,
    proceeding,
    status,
    areaInterest,
    type,
    year,
    authors,
    region,
    getDataFormEditError,
    isEditing,
    toggleLayer,
    loadingPostForm,
    postDataError,

    changeValues,
    hideFormManagerLayer,
  } = props;


  const callbackPostSuccess = useCallback(() => {
    const {
      searchLegislativeProcesses,
      hideFormManagerLayer,
    } = props;

    searchLegislativeProcesses();
    hideFormManagerLayer();
  }, [props]);


  const handlePost = useCallback((formParams) => {
    const { create, update } = props;

    if(formParams.id) {
      update({ formParams });
    }
    else {
      create({
        formParams,
        callbackSuccess: callbackPostSuccess
      });
    }
  }, [callbackPostSuccess, props]);


  const onSearchAuthors = useCallback((text) => {
    if (text.length < 2) return;

    const { getAuthorsOptions } = props;
    getAuthorsOptions({ name: text });
  }, [props]);


  if(toggleLayer && toggleLayer === 'manager') {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={hideFormManagerLayer}
            onEsc={hideFormManagerLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ top: 'large', bottom: 'xsmall' }}
              overflow='auto'
              flex
            >
              {isEditing && !id && !getDataFormEditError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {getDataFormEditError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: hideFormManagerLayer,
                  }}
                />
              ) : null}

              {!getDataFormEditError && ((isEditing && id) || !isEditing) ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value={id ? 'Editar dados do documento' : 'Novo documento'} />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={hideFormManagerLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Tipo' required />

                      <SelectSearch
                        options={documentTypeOptions}
                        value={type ?? ''}
                        labelKey='name'
                        valueKey={{ key: 'id', reduce: true }}
                        emptySearchMessage='Selecione uma opção'
                        onChange={({ value: nextValue }) =>
                          changeValues({ fieldName: 'type', value: nextValue }, 'changeFormManagerField')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='type' />
                    </Box>


                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Status' required />

                      <SelectSearch
                        options={statusLegislativeProcessOptions}
                        value={status ?? ''}
                        labelKey='name'
                        valueKey={{ key: 'id', reduce: true }}
                        emptySearchMessage='Selecione uma opção'
                        onChange={({ value: nextValue }) =>
                          changeValues({ fieldName: 'status', value: nextValue }, 'changeFormManagerField')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='status' />
                    </Box>


                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Área de interesse' />

                      <SelectSearch
                        options={interestLegislativeProcessOptions}
                        value={areaInterest ?? ''}
                        labelKey='name'
                        valueKey={{ key: 'id', reduce: true }}
                        emptySearchMessage='Selecione uma opção'
                        onChange={({ value: nextValue }) =>
                          changeValues({ fieldName: 'areaInterest', value: nextValue }, 'changeFormManagerField')
                        }
                      />
                    </Box>


                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Box direction='row' gap='small'>
                        <Box width='30vw'>
                          <TitleField text='Número' required />

                          <TextInput
                            type='number'
                            min={0}
                            value={number ?? ''}
                            onChange={event =>
                              changeValues({ fieldName: 'number', value: event.target.value }, 'changeFormManagerField')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='number' />
                        </Box>

                        <Box width='40vw'>
                          <TitleField text='Ano' required />

                          <TextInput
                            type='number'
                            min={0}
                            value={year ?? ''}
                            onChange={event =>
                              changeValues({ fieldName: 'year', value: event.target.value }, 'changeFormManagerField')
                            }
                          />

                          <ErrorForm errorsList={postDataError.errors} fieldName='year' />
                        </Box>

                        <Box margin={{ left: 'xsmall' }} width='30vw'>
                          <TitleField text='Em tramitação' />

                          <SelectSearch
                            options={selectYesNoOptions}
                            value={proceeding ?? ''}
                            labelKey='name'
                            valueKey={{ key: 'id', reduce: true }}
                            emptySearchMessage='Selecione uma opção'
                            onChange={({ value: nextValue }) =>
                              changeValues({ fieldName: 'proceeding', value: nextValue }, 'changeFormManagerField')
                            }
                          />
                        </Box>
                      </Box>
                    </Box>


                    {/* Region */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Região (bairro, cidade)' />

                      <TextInput
                        maxLength={50}
                        value={region ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'region', value: event.target.value }, 'changeFormManagerField')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='region' />
                    </Box>


                    {/* Authors */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Box
                        round='xsmall'
                        border={{ color: 'light-4' }}
                        background='white'
                      >
                        {/* fields */}
                        <Box
                          direction='row'
                          border={{ color: 'light-5', side: 'bottom' }}
                          pad={{
                            vertical: 'xxsmall',
                            horizontal: 'xxsmall',
                          }}
                        >
                          <Box width='large'>
                            <TextInput
                              plain
                              placeholder='cadastrar autor...'
                              onChange={event => {
                                const { value: newValue } = event.target;

                                setName(newValue);
                                onSearchAuthors(newValue);
                              }}
                              value={name}
                              suggestions={
                                authorsOptions.map(({ name }) => ({
                                  label: (
                                    <Box
                                      pad='small'
                                    >
                                      <Text size='xsmall' color='dark-5'>Utilizar esse:</Text>
                                      <Text size='small' color='dark-3'>{name}</Text>
                                    </Box>
                                  ),
                                  value: { name }
                                }))
                              }
                              onSuggestionSelect={event => {
                                const { name } = event.suggestion.value;
                                setName(name);
                              }}
                            />
                          </Box>

                          <Button
                            icon={<AddCircle color='neutral-1' />}
                            disabled={!name}
                            onClick={() => {
                              changeValues(
                                { ac: 'add', fieldName: 'authors', value: name }, 'addOrRemoveinList'
                              );

                              setName('');
                            }}
                          />
                        </Box>

                        {/* list authors exists */}
                        {authors && authors.map((item, index) => (
                          <Box
                            key={index}
                            align='center'
                            direction='row'
                            pad={{
                              horizontal: 'small',
                            }}
                            border={{ color: 'light-5', side: 'bottom' }}
                            justify='between'
                          >
                            <Text size='small' color='dark-5'>{item}</Text>

                            <Anchor
                              icon={<Close size='small' />}
                              color='status-critical'
                              title='Remover'
                              onClick={() =>
                                changeValues(
                                  { ac: 'delete', fieldName: 'authors', value: item }, 'addOrRemoveinList'
                                )
                              }
                            />
                          </Box>
                        ))}
                      </Box>

                      <ErrorForm errorsList={postDataError.errors} fieldName='options' />
                    </Box>


                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <TitleField text='Assunto' required />

                      <TextArea
                        rows={5}
                        resize='vertical'
                        maxLength={2000}
                        value={menu ?? ''}
                        onChange={event =>
                          changeValues({ fieldName: 'menu', value: event.target.value }, 'changeFormManagerField')
                        }
                      />

                      <ErrorForm errorsList={postDataError.errors} fieldName='menu' />
                    </Box>
                  </Box>


                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        onClick={hideFormManagerLayer}
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                        disabled={ loadingPostForm }
                        onClick={() =>
                          handlePost({
                            id,
                            menu,
                            number,
                            proceeding,
                            status,
                            areaInterest,
                            type,
                            year,
                            authors,
                            region,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    )
  }

  return null;
}

const mapStateToProps = ({ legislativeProcessesReducer: { formManager }, selectOptionsReducer, layerReducer }) => ({
  documentTypeOptions: selectOptionsReducer.documentTypeOptions,
  authorsOptions: selectOptionsReducer.authorsOptions,
  statusLegislativeProcessOptions: selectOptionsReducer.statusLegislativeProcessOptions,
  interestLegislativeProcessOptions: selectOptionsReducer.interestLegislativeProcessOptions,

  id: formManager.id,
  menu: formManager.menu,
  number: formManager.number,
  proceeding: formManager.proceeding,
  status: formManager.status,
  areaInterest: formManager.areaInterest,
  type: formManager.type,
  year: formManager.year,
  authors: formManager.authors,
  region: formManager.region,

  isEditing: formManager.isEditing,
  getDataFormEditError: formManager.getDataFormEditError,

  loadingPostForm: formManager.loadingPostForm,
  postDataError: formManager.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getAuthorsOptions,
    update,
    create,
    changeValues,
    searchLegislativeProcesses,
    hideFormManagerLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);
