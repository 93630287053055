import TYPES from './types';
import { details } from '../../../api/common';
import { printOut } from '../components/print/print';
import * as analytics from '../../../analytics';

const _notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

const _command = params =>
  async dispatch => {
    try
    {
      dispatch(_notification('Gerando arquivo. Aguarde...'));

      const [serviceDetail, voterDetail] =
        await Promise.all([
          details(params.id, 'atendimento.php'),
          details(params.electorId, 'eleitor.php'),
        ]);

      printOut({
        data: {
          service: serviceDetail.data,
          voter: voterDetail.data,
        },
        fileName: `atendimento-${serviceDetail.data.reference}.html`,
      });
    }
    catch(error)
    {
      dispatch(_notification('Ops... não conseguimos gerar o arquivo.'))
    }
  }

export const generatePrint = params => {
  //ga
  analytics.registerEvent({
    category: analytics.categories.service,
    action: analytics.actions.servicePrint,
  });

  return _command(params);
}
