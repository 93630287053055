import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer,  Text, Box, Anchor } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import {
  enableOrDisable,
  toggleFormChangeStatusLayer,
} from '../../actions/form-change-status';

class FormChangeStatus extends Component {
  handlePost = formParams => {
    const {
      enableOrDisable,
    } = this.props;

    enableOrDisable({ formParams });
  };

  render() {
    const {
      id,
      status,
      name,
      email,

      toggleLayer,
      loadingPostForm,
      postDataError,

      toggleFormChangeStatusLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'changeStatus') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormChangeStatusLayer}
              onEsc={toggleFormChangeStatusLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value={
                    status ? 'Inativar usuário' : 'Reativar usuário'}
                  />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleFormChangeStatusLayer}
                  />
                </Box>


                <Box flex overflow='auto'>
                  <Box
                    flex={{ shrink: 0 }}
                    margin={{ horizontal: 'large' }}
                    gap='xsmall'
                  >
                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>Nome</Text>
                      <Text size='medium' color='dark-4'>{name}</Text>
                    </Box>

                    <Box
                      direction='row'
                      gap='small'
                      align='baseline'
                    >
                      <Text size='small' color='dark-2'>E-mail</Text>
                      <Text size='medium' color='dark-4'>{email}</Text>
                    </Box>

                    <Box
                      margin={{ top: 'medium' }}
                    >
                      <Text
                        size='small'
                        textAlign='start'
                        color='status-warning'
                      >
                        {status ?
                          <>Após a confirmação o usuário deixará de acessar o sistema.</> :
                          <>Após a confirmação o usuário poderá acessar o sistema.</>}
                      </Text>
                    </Box>

                    <Box
                      flex={{ shrink: 0 }}
                    >
                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>
                  </Box>
                </Box>

                {/* botoes acao */}
                <Box
                  direction='row'
                  pad={{ vertical: 'medium' }}
                  justify='center'
                  align='center'
                  gap='small'
                >
                  <Box width='xsmall'>
                    <Anchor
                      color='dark-3'
                      label='Não'
                      onClick={toggleFormChangeStatusLayer}
                    />
                  </Box>

                  <Box width='small'>
                    <Button
                      color='brand'
                      primary
                      label={ !loadingPostForm ?
                        (status ? 'SIM, inativar' : 'SIM, reativar') :
                        (status ? 'Inativando...' : 'Reativando...')
                      }
                      disabled={ loadingPostForm }
                      onClick={() =>
                        this.handlePost({
                          id,
                          status: !status,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ settingsUserReducer: { formChangeStatus }, layerReducer }) => ({
  id: formChangeStatus.id,
  status: formChangeStatus.status,
  name: formChangeStatus.name,
  email: formChangeStatus.email,

  loadingPostForm: formChangeStatus.loadingPostForm,
  postDataError: formChangeStatus.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    enableOrDisable,
    toggleFormChangeStatusLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormChangeStatus);
