import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { changeStatusLegislativeProcess } from '../../../api/legislative-processes';

const loadingPostChangeStatusToggle = () => ({
  type: TYPES.loadingFormChangeStatus,
});

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const changeStatus = params => {
  return dispatch => {
    dispatch(loadingPostChangeStatusToggle());

    changeStatusLegislativeProcess(params.formParams)
      .then(response => {
        dispatch(toggleFormChangeStatusLayer());
        dispatch(loadingPostChangeStatusToggle());

        dispatch({
          type: TYPES.updateItemListingChangeStatus,
          payload: response,
        });
        dispatch(notification('O status do documento foi alterado!'))
      })
      .catch(responseFail => {
        dispatch(loadingPostChangeStatusToggle());
        dispatch({
          type: TYPES.formChangeStatusError,
          payload: responseFail.response
        });
      })
  }
}

export const toggleFormChangeStatusLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.legislative,
      action: analytics.actions.legislativeChangeStatus,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'changeStatus' },
      { type: TYPES.setDataFormStatus, payload: params },
    ];
  }

  return { type: COMMON_TYPES.toggleLayer, payload: null };
};
