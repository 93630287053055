import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { remove as d } from '../../../api/common';

const loadingPostDeleteToggle = () => ({
  type: TYPES.loadingFormDelete,
});

const notification = message => ({
  type: TYPES.showNotification,
  payload: message,
});

export const remove = params => {
  return dispatch => {
    dispatch(loadingPostDeleteToggle());

    d(params.formParams.id, 'atendimento.php')
      .then(response => {
        dispatch(loadingPostDeleteToggle());
        dispatch(notification('O atendimento foi removido!'));
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostDeleteToggle());
        dispatch({
          type: TYPES.formPostDeleteError,
          payload: responseFail.response
        });
      })
  }
}

export const toggleFormDeleteLayer = params => {
  if(params && params.id) {
    analytics.registerEvent({
      category: analytics.categories.service,
      action: analytics.actions.serviceDelete,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'delete' },
      { type: TYPES.setDataFormDelete, payload: params },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: TYPES.setDataFormDelete, payload: null },
  ];
};
