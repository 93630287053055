import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Text, Box, TextInput, Anchor, Button } from 'grommet';
import { UploadOption, Attachment, FormClose } from 'grommet-icons';

import { ErrorForm } from '../../../../components';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';
import visibleContentWidth from '../../../../constants/layer-content-width';

import { formattedNumber } from '../../../../utils';

import { uploadFile, chooseFile, toggleFormCreateArchiveLayer } from '../../actions/form-archive';

class FormCreateArchive extends Component {
  handlePost = formParams => {
    const { uploadFile } = this.props;
    uploadFile({ formParams });
  };

  handlerChooseFile = formFile => {
    const { chooseFile } = this.props;
    chooseFile(formFile);
  }

  render() {
    const {
      id,
      file,

      toggleLayer,
      loadingPostForm,
      postDataError,
      toggleFormCreateArchiveLayer,
    } = this.props;


    if(toggleLayer && toggleLayer === 'createArchive') {
      return (
        <ResponsiveContext.Consumer>
          {size => (
            <Layer
              responsive={false}
              full='vertical'
              position='left'
              onClickOutside={toggleFormCreateArchiveLayer}
              onEsc={toggleFormCreateArchiveLayer}
            >
              <Box
                fill='vertical'
                width={visibleWidth[size]}
                pad={{ top: 'large', bottom: 'xsmall' }}
                overflow='auto'
                flex
              >
                <Box
                  direction='row'
                  justify='between'
                  align='baseline'
                  margin={{ horizontal: 'large' }}
                >
                  <TitleDetail value='Enviar arquivo do atendimento' />

                  <Button
                    plain
                    title='Fechar'
                    icon={<FormClose />}
                    onClick={toggleFormCreateArchiveLayer}
                  />
                </Box>


                {file && (
                  <>
                    <Box
                      align='center'
                      margin={{ top: 'medium' }}
                      flex
                      overflow='auto'
                    >
                      <Box
                        width={visibleContentWidth[size]}
                        round='xsmall'
                        pad={{ vertical: 'medium' }}
                        border={{ color: 'light-3' }}
                      >
                        <SimpleDetail
                          title='Nome'
                          value={file.name}
                          truncateValue={true}
                        />

                        <SimpleDetail
                          title='Tipo'
                          value={file.type}
                          truncateValue={true}
                        />

                        <SimpleDetail
                          title='Tamanho'
                          value={`${formattedNumber(file.size)} bytes`}
                        />

                        <Hr />

                        <Box
                          direction='row'
                          margin={{
                            horizontal: 'large'
                          }}
                          flex={{ shrink: 0 }}
                          alignSelf='center'
                          align='center'
                          gap='small'
                        >
                          <Text size='small' color='status-warning' textAlign='center'>
                            Clique em salvar para finalizar o processo de upload do arquivo.
                          </Text>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                      align='center'
                      gap='small'
                      flex={{ shrink: 0 }}
                    >
                      <Box width='xsmall'>
                        <Anchor
                          color='dark-3'
                          label='Cancelar'
                          onClick={toggleFormCreateArchiveLayer}
                        />
                      </Box>

                      <Box width='small'>
                        <Button
                          color='brand'
                          primary
                          label={ !loadingPostForm ? 'Salvar': 'Salvando...' }
                          disabled={ loadingPostForm }
                          onClick={() =>
                            this.handlePost({
                              id,
                              file
                            })
                          }
                        />
                      </Box>
                    </Box>
                  </>
                )}

                {!file && (
                  <Box
                    flex
                    overflow='auto'
                    justify='center'
                  >
                    <Box
                      align='center'
                      margin={{
                        vertical: size === 'small' ? 'medium' : 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      gap='medium'
                    >
                      <Box
                        margin={{
                          bottom: 'medium'
                        }}
                      >
                        <UploadOption size='xlarge' color='brand' />
                      </Box>

                      <Box
                        pad={{
                          horizontal: 'xlarge'
                        }}
                      >
                        <Text size='small' textAlign='center'>
                          O arquivo não pode ultrapassar <strong>1MB</strong> e deve ter uma das extensões: <strong>.jpg</strong>, <strong>.png</strong>, <strong>.pdf</strong>.
                        </Text>
                      </Box>

                      <Box
                        border={{
                          style: 'dashed'
                        }}
                        round='xsmall'
                        pad={{
                          horizontal: 'large',
                          vertical: 'small'
                        }}
                      >
                        <TextInput
                          style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            cursor: 'pointer',
                            height: '100%',
                            width: '100%'
                          }}
                          type='file'
                          accept='image/*,application/pdf'
                          onChange={event => {
                            this.handlerChooseFile(event.target.files[0]);
                          }}
                        />

                        <Box
                          direction='row'
                          align='center'
                          gap='small'
                        >
                          <Attachment color='dark-4' />
                          <Text color='dark-5'>selecione o arquivo ou solte ele aqui</Text>
                        </Box>
                      </Box>

                      <ErrorForm errorsList={postDataError.errors} />
                    </Box>

                    <Box
                      direction='row'
                      pad={{ vertical: 'medium' }}
                      justify='center'
                    >
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        onClick={toggleFormCreateArchiveLayer}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      )
    }

    return null;
  }
}

const mapStateToProps = ({ servicesReducer: { formArchive }, layerReducer }) => ({
  id: formArchive.id,
  file: formArchive.file,

  loadingPostForm: formArchive.loadingPostForm,
  postDataError: formArchive.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    uploadFile,
    chooseFile,
    toggleFormCreateArchiveLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateArchive);
